import * as React from "react";
import { Container } from "react-bootstrap";
import { graphql } from "gatsby";
import Activation from "../../components/activation";
import Feature from "../../components/Feature";
import FeaturesSummary from "../../components/productPage/featuresSummary";
import Layout from "../../components/layout";
import { container } from "./guest-experience-app.module.css";
import { SEO } from "../../components/seo";

const GuestExperienceAppPage = ({ data }) => {
  return (
    <Layout pageTitle="Guest Experience App">
      <Container fluid className={container}>
        <Feature
          title="The Ultimate Guest Experience App"
          subtitle="Transform your guests' charter experience with Floatist's Guest Experience App, a centralized platform that streamlines every step of their journey. From easy booking management to accessing detailed itineraries and essential documents, everything is at their fingertips. This innovative app not only simplifies administrative processes but also empowers guests with the information they need for a relaxed and informed trip. "
          imageData={data.longscrollGuest.childImageSharp.gatsbyImageData}
          textSide="right"
        />

        <Feature
          id="info"
          title="Base, booking and itinerary info"
          subtitle="The guest experience app allows you to provide all necessary information in one place. It is completely customizable to your liking, and it can provice your guests with immediate access to base details, booking specifics, and itineraries."
          imageData={data.base.childImageSharp.gatsbyImageData}
          textSide="left"
        />

        <Feature
          id="equipment"
          title="Yacht equipment information"
          subtitle="For bareboat charters, you can provide your charter guests with the knowledge they need through the 'Yacht Equipment Manual' section in the Floatist app. This comprehensive resource offers detailed information on how to operate yacht equipment, ensuring guests feel confident and informed throughout their charter."
          imageData={data.equipment.childImageSharp.gatsbyImageData}
          textSide="right"
        />

        <Feature
          id="extras"
          title="Booking management & Extras"
          subtitle="Elevate your guests' charter experience with the convenience of booking extras directly through the Floatist app. From water sports equipment to special onboard amenities, guests can effortlessly personalize their trip, enhancing their voyage with just a few taps."
          imageData={data.extras.childImageSharp.gatsbyImageData}
          textSide="left"
        />

        <Feature
          id="support"
          title="Support chat"
          subtitle="Enhance guest satisfaction with Floatist’s support chat functionality, enabling real-time communication between charter guests and your company. This direct line of interaction allows for quick resolution of inquiries and issues, ensuring a smooth and enjoyable experience for every guest."
          imageData={data.support.childImageSharp.gatsbyImageData}
          textSide="right"
        />

        <Feature
          id="crewlist"
          title="Collect crew list information"
          subtitle="Streamline your pre-charter preparations and allow guests to conveniently submit all necessary crew details ahead of time, ensuring that you have accurate and complete information for safety and regulatory compliance before departure. This proactive approach enhances operational efficiency and provides a smoother start to the charter experience."
          imageData={data.crewlist.childImageSharp.gatsbyImageData}
          textSide="left"
        />

        <Feature
          id="push"
          title="Send and receive Push Notifications"
          subtitle="Ensure that both staff and charter guests never miss important updates thanks to Push Notifications. Floatist sends timely alerts directly to users' devices, providing notifications about crucial events and changes, keeping everyone informed and responsive throughout the charter process."
          imageData={data.push.childImageSharp.gatsbyImageData}
          textSide="right"
        />
      </Container>

      <FeaturesSummary />
      <Activation />
    </Layout>
  );
};

export default GuestExperienceAppPage;

export const Head = () => (
  <SEO
    description="Transform your guests' charter experience with Floatist's Guest
    Experience App, a centralized platform that streamlines every step of their
    journey"
  />
);

export const query = graphql`
  query {
    longscrollGuest: file(relativePath: { eq: "longscroll-guest.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    base: file(relativePath: { eq: "base-booking-itinerary.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    equipment: file(relativePath: { eq: "equipment-instructions.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    extras: file(relativePath: { eq: "extras.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    support: file(relativePath: { eq: "support.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    crewlist: file(relativePath: { eq: "crewlist.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    push: file(relativePath: { eq: "push.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
